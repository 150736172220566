<template>
  <div class="campaign-quick-glance">
    <div class="row align-items-center mb-3">
      <div class="col-12 col-md-6">
        <div class="row justify-content-between align-items-center">
          <div v-if="campaign.logo" class="col-sm-4 col-md-2 campaign-logo">
            <router-link :to="{ name: 'campaign', params: { id: campaign.campaign_id } }">
              <!-- eslint-disable-next-line -->
              <img :src="campaign.logo" class="img-fluid">
            </router-link>
          </div>
          <div class="col-sm-8 col-md-4 campaign-title">
            <router-link :to="{ name: 'campaign', params: { id: campaign.campaign_id } }">
              <strong>{{ campaign.title }}</strong>
              <small>{{ dateRange }}</small>
            </router-link>
          </div>
          <div class="col-sm-12 col-md-6 donation-progress">
            <strong :title="totalDollars">{{ formatAmountStat(totalDollars, '$ 0,0') }}</strong>
            <small>of {{ formatAmountStat(targets.all, '$ 0,0') }} funded</small>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-9">
            <div class="total-remaining">
              <strong>{{ formatAmountStat(totalRemaining, '$ 0,0') }}</strong>
              <small>remaining</small>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                :class="`${progressBarStatus(percentage)}`"
                role="progressbar"
                :style="`width: ${percentage}%`"
                :aria-valuenow="percentage"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col-3">
            <div class="progress-label">
              <strong>{{ formatPercentStat(percentage / 100, '0,0%') }}</strong>
              <small>of Goal</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col total-sum">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ formatAmountStat(totalDollars, '$ 0,0') }}</h5>
            <p class="card-text">Total Donated</p>
          </div>
        </div>
      </div>
      <div class="col total-count">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ formatNumberStat(totalDonations, '0,0') }}</h5>
            <p class="card-text">Total Number of Donations</p>
          </div>
        </div>
      </div>
      <div class="col per-hour-count">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ formatNumberStat(donationsCountPerHour, '0,0') }}</h5>
            <p class="card-text">Donations this Hour</p>
          </div>
        </div>
      </div>
      <div class="col per-hour-sum">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ formatAmountStat(donationsPerHour, '$ 0,0') }}</h5>
            <p class="card-text">$/hr</p>
          </div>
        </div>
      </div>
      <div class="col per-day-sum">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ formatAmountStat(donationsPerDay, '$ 0,0') }}</h5>
            <p class="card-text">$/day</p>
          </div>
        </div>
      </div>
      <div class="col total-avg">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              {{ donationsAverage('all', '$ 0,0') }}
              <font-awesome-icon
                :icon="averageMovementIcon(averageMovement('all'))"
                class="ml-2"
                :class="averageMovement('all')"
              />
            </h5>
            <p class="card-text">Average Donation Amount</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card donations single-donations">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row align-items-center mb-2">
                <div class="col-5 donations-type">
                  <font-awesome-icon icon="money-bill" class="text-success mr-3" /> Single Donations
                </div>
                <div class="col-7">
                  <div class="row align-items-center">
                    <div class="col-9">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          :class="`${progressBarStatus(percentageSingle)}`"
                          role="progressbar"
                          :style="`width: ${percentageSingle}%`"
                          :aria-valuenow="percentageSingle"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="progress-label">
                        <strong>{{ formatPercentStat(percentageSingle / 100, '0,0%') }}</strong>
                        <small>of Goal</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row donations-stats">
                <div class="col">
                  <strong :title="totalDollarsSingle">
                    {{ formatAmountStat(totalDollarsSingle, '$ 0,0') }}
                  </strong>
                  <small>Donated</small>
                </div>
                <div class="col">
                  <strong>{{ formatNumberStat(totalDonationsSingle, '0,0') }}</strong>
                  <small>Number of Donations</small>
                </div>
                <div class="col">
                  <strong>{{ formatAmountStat(donationsPerHourSingle, '$ 0,0') }}</strong>
                  <small>$/hr</small>
                </div>
                <div class="col">
                  <strong>
                    {{ donationsAverage('single', '$ 0,0') }}
                    <font-awesome-icon
                      :icon="averageMovementIcon(averageMovement('single'))"
                      class="ml-2"
                      :class="averageMovement('single')"
                    />
                  </strong>
                  <small>Average Amount</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card donations recurring-donations">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row align-items-center mb-2">
                <div class="col-5 donations-type">
                  <font-awesome-icon icon="redo" class="text-warning mr-3" /> Monthly Donations
                </div>
                <div class="col-7">
                  <div class="row align-items-center">
                    <div class="col-9">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          :class="`${progressBarStatus(percentageRecurring)}`"
                          role="progressbar"
                          :style="`width: ${percentageRecurring}%`"
                          :aria-valuenow="percentageRecurring"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="progress-label">
                        <strong>{{ formatPercentStat(percentageRecurring / 100, '0,0%') }}</strong>
                        <small>of Goal</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row donations-stats">
                <div class="col">
                  <strong :title="totalDollarsRecurring">
                    {{ formatAmountStat(totalDollarsRecurring, '$ 0,0') }}
                  </strong>
                  <small>Donated</small>
                </div>
                <div class="col">
                  <strong>{{ formatNumberStat(totalDonationsRecurring, '0,0') }}</strong>
                  <small>Number of Donations</small>
                </div>
                <div class="col">
                  <strong>{{ formatAmountStat(donationsPerHourRecurring, '$ 0,0') }}</strong>
                  <small>$/hr</small>
                </div>
                <div class="col">
                  <strong>
                    {{ donationsAverage('recurring', '$ 0,0') }}
                    <font-awesome-icon
                      :icon="averageMovementIcon(averageMovement('recurring'))"
                      class="ml-2"
                      :class="averageMovement('recurring')"
                    />
                  </strong>
                  <small>Average Amount</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';

export default {
  name: 'CampaignQuickGlance',
  mixins: [hasStatistics, hasTimeDate],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    promotions: {
      type: Array,
      required: false,
      default: () => [],
    },
    donations: {
      type: Array,
      required: false,
      default: () => [],
    },
    statistics: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateRange() {
      const startsAt = this.formatDate(this.campaign.starts_at, 'MMM DD');
      const endsAt = this.formatDate(this.campaign.ends_at, 'MMM DD');

      return `${startsAt} - ${endsAt}`;
    },
    percentage() {
      if (this.statistics) {
        return Math.round(
          ((this.statistics.total_sum + this.statistics.pledges_total_sum) / this.targets.all) * 100
        );
      }

      return 0;
    },
    percentageSingle() {
      if (!this.statistics) {
        if (this.singlePromotion.intentions) {
          if (this.singlePromotion.intentions[0].counter) {
            return (this.totalDollarsSingle / this.singlePromotion.intentions[0].counter) * 100;
          }
        }
      }

      if (this.targets.single) {
        // console.log('CampaignQuickGlance computed percentageSingle() statistics / targets', {
        //   total: this.statistics.total_single_sum,
        //   target: this.targets.single,
        // });
        return (
          ((this.statistics.total_single_sum + this.statistics.pledges_total_sum) /
            this.targets.single) *
          100
        );
      }

      return 0;
    },
    percentageRecurring() {
      if (!this.statistics) {
        if (this.recurringPromotion.intentions) {
          if (this.recurringPromotion.intentions[0].counter) {
            return (
              (this.totalDollarsRecurring / this.recurringPromotion.intentions[0].counter) * 100
            );
          }
        }
      }

      if (this.targets.recurring) {
        return (this.statistics.total_recurring_sum / this.targets.recurring) * 100;
      }

      return 0;
    },
    targets() {
      if (this.campaign.meta && this.campaign.meta.targets) {
        return this.campaign.meta.targets;
      }

      return {
        all: 0,
        single: 0,
        recurring: 0,
      };
    },
    singlePromotion() {
      let singles = this.promotions.filter((promotion) => {
        if (promotion.intentions[0].meta && promotion.intentions[0].meta.tracked_donation_type) {
          return promotion.intentions[0].meta.tracked_donation_type === 'single';
        }

        return false;
      });

      singles = singles.filter((promotion) => {
        return this.isBetween(promotion.starts_at, promotion.ends_at);
      });

      return singles.length ? singles[0] : {};
    },
    recurringPromotion() {
      let recurrings = this.promotions.filter((promotion) => {
        if (promotion.intentions[0].meta && promotion.intentions[0].meta.tracked_donation_type) {
          return promotion.intentions[0].meta.tracked_donation_type === 'recurring';
        }

        return false;
      });

      recurrings = recurrings.filter((promotion) => {
        return this.isBetween(promotion.starts_at, promotion.ends_at);
      });

      return recurrings.length ? recurrings[0] : {};
    },
  },
  methods: {
    progressBarStatus(percentage) {
      // console.log('CampaignQuickGlance methods progressBarStatus() :percentage', percentage);
      let status = 'progress-status-1';

      if (percentage >= 90) {
        status = 'progress-status-4';
      } else if (percentage >= 67) {
        status = 'progress-status-3';
      } else if (percentage >= 34) {
        status = 'progress-status-2';
      }

      return status;
    },
  },
};
</script>

<style scoped lang="scss"></style>
