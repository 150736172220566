<template>
  <div class="progress-bar-horizontal-container" :style="{ height }">
    <div class="progress">
      <div
        :class="getClass()"
        role="progressbar"
        :style="{ width: `${percentage}%` }"
        :aria-valuenow="percentage"
        :aria-valuemin="min"
        :aria-valuemax="max"
      >
        <span class="progress-label px-2 text-right">{{ progressLabel }}</span>
      </div>
    </div>
    <span>{{ endLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarHorizontal',
  props: {
    endLabel: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    percentage: {
      type: Number,
      required: false,
      default: 0,
    },
    progressLabel: {
      type: String,
      required: false,
      default: '0',
    },
  },
  computed: {
    getPercentage() {
      return this.percentage.toFixed(2);
    },
  },
  methods: {
    getClass() {
      const classes = ['progress-bar'];

      if (this.percentage >= 100) {
        classes.push('bg-success');
      } else {
        classes.push('bg-secondary');
      }

      /*
      // This might be used later
      switch (true) {
        case this.percentage < 25:
          classes.push('bg-danger');
          break;
        case this.percentage < 50:
          classes.push('bg-warning');
          break;
        case this.percentage < 75:
          classes.push('bg-info');
          break;
        case 75 <= this.percentage:
          classes.push('bg-success');
          break;
      }
      */

      return classes.join(' ');
    },
    getOffset() {
      const classes = ['offset'];

      if (this.percentage > 50) {
        classes.push('offset-left');
      } else {
        classes.push('offset-right');
      }

      return classes.join(' ');
    },
  },
};
</script>

<style scoped lang="scss">
.progress-label {
  .bg-secondary & {
    text-shadow: 1px 0 0 var(--secondary), -1px 0 0 var(--secondary), 0 1px 0 var(--secondary),
      0 -1px 0 var(--secondary);
  }

  .bg-success & {
    text-shadow: 1px 0 0 var(--success), -1px 0 0 var(--success), 0 1px 0 var(--success),
      0 -1px 0 var(--success);
  }
}
</style>
