<template>
  <div class="row campaign-strap">
    <div v-if="isPresentationMode" class="col-12 text-center pt-1 pb-0 meta">
      <router-link class="btn btn-primary ml-2" :to="{ name: 'dashboard' }">
        <font-awesome-icon icon="compress" /> Exit
      </router-link>
    </div>
    <div class="col-12 p-3 campaign-strap-data">
      <CampaignQuickGlance
        :campaign="campaign"
        :donations="donations"
        :promotions="promotions"
        :statistics="statistics"
      />
    </div>
  </div>
</template>

<script>
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import CampaignQuickGlance from './CampaignQuickGlance';

export default {
  name: 'CampaignStrap',
  components: { CampaignQuickGlance },
  mixins: [hasStatistics, hasTimeDate, helpers],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    donations: {
      type: Array,
      required: false,
      default: () => [],
    },
    promotions: {
      type: Array,
      required: false,
      default: () => [],
    },
    pledges: {
      type: Array,
      required: false,
      default: () => [],
    },
    progressBarHeight: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    dateRange() {
      const startsAt = this.formatDate(this.campaign.starts_at, 'MMM DD');
      const endsAt = this.formatDate(this.campaign.ends_at, 'MMM DD');

      return `${startsAt} - ${endsAt}`;
    },
    isPresentationMode() {
      return this.$route.path.split('/')[1] === 'presentation';
    },
    statistics() {
      return this.campaign.statistics ?? {};
    },
    percentage() {
      return (this.statistics.total_sum / this.campaign.target) * 100;
    },
    progressLabel() {
      return this.percentage.toFixed(1).toString();
    },
    allDonations() {
      let formIds = [];

      if (this.hasProperty(this.campaign.meta, 'forms') && this.campaign.meta.forms.length) {
        formIds = this.campaign.meta.map((form) => {
          return form.id;
        });
      } else {
        formIds = this.campaign.meta.formIds;
      }

      return this.donations.filter((donation) => {
        return formIds.includes(donation.form_id);
      });
    },
    // promotions() {
    //   return this.$store.getters['promotions/getAll'];
    // },
  },
  created() {
    // this.fetchData();
  },
  methods: {
    fetchData() {
      const { starts_at, ends_at } = this.campaign;
      const payload = {
        id: this.campaign.campaign_id,
        params: {
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
        },
      };

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('CampaignStrap fetchData() then', payload, response.data);
          this.allDonations = response.data.data;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.meta {
  a {
    &,
    &:link,
    &:visited {
      color: rgba(#fff, 0.5);
    }
  }
}
</style>
