<template>
  <Line :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script setup>
import { defineProps, onMounted } from 'vue';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const props = defineProps({
  chartData: {
    type: Object,
    default() {
      return {};
    },
  },
  chartOptions: {
    type: Object,
    default() {
      return {};
    },
  },
});

onMounted(() => {
  props.chartData;
});
</script>
