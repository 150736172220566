<template>
  <div class="progress-bar-horizontal-container" :style="{ height }">
    <div class="col-md-2 text-nowrap">{{ progressLabel }} / {{ max }}</div>
    <div class="col-md progress-gifts">
      <font-awesome-icon
        icon="gift"
        size="lg"
        v-for="n in 10"
        :key="`progress-gift-${n}`"
        :class="getGiftCass(n)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressGiftsHorizontal',
  props: {
    endLabel: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    percentage: {
      type: Number,
      required: false,
      default: 0,
    },
    progressLabel: {
      type: String,
      required: false,
      default: '0',
    },
  },
  methods: {
    getClass() {
      const classes = ['progress-bar'];

      classes.push('bg-secondary');

      /*
      // This might be used later
      switch (true) {
        case this.percentage < 25:
          classes.push('bg-danger');
          break;
        case this.percentage < 50:
          classes.push('bg-warning');
          break;
        case this.percentage < 75:
          classes.push('bg-info');
          break;
        case 75 <= this.percentage:
          classes.push('bg-success');
          break;
      }
      */

      return classes.join(' ');
    },
    getGiftCass(index) {
      const classes = [];
      const range = index * 10;
      if (range < this.getRoundedPercentage()) {
        classes.push('active');
      } else {
        classes.push('inactive');
      }

      return classes.join(' ');
    },
    getOffset() {
      const classes = ['offset'];

      if (this.percentage > 50) {
        classes.push('offset-left');
      } else {
        classes.push('offset-right');
      }

      return classes.join(' ');
    },
    getRoundedPercentage() {
      return Math.round(this.percentage / 100) * 100;
    },
  },
};
</script>

<style></style>
