<template>
  <div class="promotion-stats">
    <div class="row text-center">
      <div class="col" v-if="intentionIs(['target', 'match'])">
        <span class="donation-stats-number">
          {{ goal }}
        </span>
        <span class="donation-stats-label">
          {{ intentionTypeLabel() }}
        </span>
      </div>
      <div class="col">
        <span class="donation-stats-number">
          {{ formatNumberStat(statsDonationsTotal) }}
        </span>
        <span class="donation-stats-label">
          of {{ intentionMeta.tracked_donation_count }} Donations
        </span>
      </div>
      <div class="col">
        <span class="donation-stats-number">
          {{ formatAmountStat(statsDollarsTotal) }}
        </span>
        <span class="donation-stats-label">Total Donated</span>
      </div>
      <div class="col">
        <span class="donation-stats-number">
          {{ formatAmountStat(target, targetFormat) }}
        </span>
        <span class="donation-stats-label">Target</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import hasIntentions from 'mixins/hasIntentions';
import hasStatistics from 'mixins/hasStatistics';

export default {
  name: 'PromotionStats',
  mixins: [hasIntentions, hasStatistics],
  props: {
    donations: {
      type: Array,
      required: true,
    },
    promotion: {
      type: Object,
      required: true,
    },
    statistics: {
      type: Object,
      required: false,
    },
  },
  computed: {
    target() {
      if (this.intention.type === 'target') {
        return (
          this.intentionMeta.tracked_donation_minimum * this.intentionMeta.tracked_donation_count
        );
      } else if (this.intention.type === 'matching') {
        return this.intention.counter;
      } else if (this.intention.type === 'giveaway') {
        return this.intention.counter;
      }

      return 0;
    },
    targetFormat() {
      if (this.intention.type === 'target' && this.intention.counter > 99999) {
        return '$ 0a';
      }
      return '$ 0,0';
    },
    isTrackingRecurring() {
      return this.intentionMeta.tracked_donation_type === 'recurring';
    },
    isTrackingSingle() {
      return this.intentionMeta.tracked_donation_type === 'single';
    },
    goal() {
      return this.formatStatByIntentionCounterType(
        this.intentionMeta.tracked_donation_minimum * this.intentionMeta.tracked_donation_count
      );
    },
    statsDonationsTotal() {
      if (this.isTrackingRecurring) {
        return this.totalCountTrackedRecurringDonations;
      } else if (this.isTrackingSingle) {
        return this.totalCountTrackedSingleDonations;
      }

      return this.totalDonations;
    },
    statsDonationsPerHour() {
      if (this.isTrackingRecurring) {
        return this.donationsPerHourRecurring;
      } else if (this.isTrackingSingle) {
        return this.donationsPerHourSingle;
      }

      return this.donationsPerHour;
    },
    statsDollarsTotal() {
      if (this.isTrackingRecurring) {
        return this.totalDollarsRecurring;
      } else if (this.isTrackingSingle) {
        return this.totalDollarsSingle;
      }

      return this.totalDollars;
    },
  },
  methods: {
    getColumnClass() {
      const classes = ['px-2'];

      if (this.intentionIs(['target', 'match'])) {
        classes.push('col-md-3');
      } else {
        classes.push('col-md-4');
      }

      return classes.join(' ');
    },
    upperFirst(data) {
      return _.upperFirst(data);
    },
  },
};
</script>

<style scoped></style>
